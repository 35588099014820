import { ApolloLink } from '@apollo/client';

import { getCSRFToken } from '@crehana/utils';

const csrfTokenMiddleware = new ApolloLink((operation, forward) => {
  const csrfToken = getCSRFToken();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-CSRFToken': csrfToken,
    },
  }));

  return forward(operation);
});

export default csrfTokenMiddleware;
