import React, { FC, Fragment, useEffect, useRef } from 'react';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

import trackPageView from './trackPageView';
import { UsePageViewInput } from './types';

interface UserActivityWraperProps {
  trackData: UsePageViewInput;
  children?: React.ReactNode;
}

/**
 * This wrapper component is meant to be used within the Layout, please don't
 * use it in app code directly
 */
const UserActivityWraper: FC<UserActivityWraperProps> = ({
  trackData: { type, id, page, pageData },
  children,
}) => {
  const { me, loading, error } = useMe();
  const tracked = useRef(false);

  useEffect(() => {
    if (type && !tracked.current && !loading && !error) {
      trackPageView({ type, id, page, user: me, pageData });
      tracked.current = true;
    }
  }, [me, loading, error]);

  return <Fragment>{children}</Fragment>;
};

export default UserActivityWraper;
