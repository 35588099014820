/* eslint-disable no-eval */
import { v4 as uuidv4 } from 'uuid';

import { customLocalStorage, getUserDistinctId } from '@crehana/utils';

import {
  GeneralTrackData,
  UserActivityEvent,
  TrackUserActivity,
  UserData,
} from './types';

export const USER_ACTIVITY_ENDPOINT = '/api/v3/user-activity/';

const showLogs = (
  event: UserActivityEvent,
  status: 'loading' | 'success' | 'error' | 'skipped',
  payload?: any,
  message?: string,
) => {
  if (customLocalStorage.getItem('user_activity_logger_enabled')) {
    const { group, groupEnd, info } = console;

    group('------ UserActivity track ------');
    info('event:', event);
    if (payload) {
      info('payload:', payload);
    }
    info('status:', status);
    if (message) {
      info('message:', message);
    }
    groupEnd();
  }
};

const trackUserActivity: TrackUserActivity = ({ event, data, user, nextUrl }) =>
  new Promise(resolve => {
    const localStorageUser = customLocalStorage.getItem('creh-tracking-data');
    const userData: UserData | undefined =
      user || (localStorageUser ? JSON.parse(localStorageUser) : undefined);

    const generalTrackData: GeneralTrackData = {
      event_uuid: uuidv4(),
      event_type: event,
      // event_date: new Date().toISOString(),
      app_platform: 'web',
      api_version: 2,
      ...(userData || {}),
      distinct_id: getUserDistinctId().distinctId,
    };
    const payload = [{ ...generalTrackData, ...data }];

    fetch(USER_ACTIVITY_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then(res => res.json())
      .then(res => {
        const status = res?.success ? 'success' : 'error';
        const message = res?.length ? res[0]?.message : res?.message;

        showLogs(event, status, payload, message);
        if (nextUrl) {
          window.location.href = nextUrl;
        } else {
          resolve({ status, message });
        }
      })
      .catch(err => {
        showLogs(event, 'error', payload, err);
        if (nextUrl) {
          window.location.href = nextUrl;
        } else {
          resolve({ status: 'error', message: err });
        }
      });
  });

export default trackUserActivity;
