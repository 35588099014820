import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';

import { Layout as CUILayout } from '@crehana/ui';
import {
  I18nProvider as CrehanaI18nProvider,
  I18nProviderProps,
} from '@crehana/i18n';
import { AuthProvider } from '@crehana/web/dist/ReactContexts/AuthContext';
import type { ColorTheme, CountryCode } from '@crehana/ts-types';
import { TrackingProvider } from '@crehana/web/dist/ReactContexts/TrackingContext';
import { CoursePreviewProvider } from '@crehana/web';
import { ProductsContainer } from '@crehana/web/dist/ReactContexts/Products';
import { UIThemeProvider } from '@crehana/web/dist/Layouts/UITheme';

import RootErrorBoundaryWithSentry from 'Jsx/Shared/ErrorBoundaries/RootErrorBoundaryWithSentry';
import { AuthDialogProvider } from 'Jsx/ContextApis/AuthDialog';

import Channels from 'Jsx/Analytics/Channels';
import FacebookPixelInit from 'Jsx/Analytics/FacebookPixelInit';
import {
  UserActivityWrapper,
  UsePageViewInput,
} from 'Jsx/Analytics/UserActivity';
import { AmplitudeLayout } from 'Jsx/Analytics/Amplitude';

import createApolloClient from './ApolloClient';
import GlobalStyles from './GlobalStyles';

import {
  CookiesDisabledWarning,
  LoadChatScripts,
  SavedCoursesProviderWithTheme,
} from './components';

import { useTrackWebPerformanceMetrics } from './web-vitals';

export interface LayoutProps {
  /** Optional custom apollo client */
  client?: ApolloClient<NormalizedCacheObject>;
  countryCode?: CountryCode;
  /**
   * We need this key to identify the page for tracking and as a header
   * `graphql-operation-source`
   */
  pageKey?: string;
  crehanaI18n?: I18nProviderProps;
  /**
   * SavedCoursesProvider provider wrapper
   * @default true
   */
  withSavedCourses?: boolean;
  /**
   * crehana-ui Layout wrapper
   *
   * @default true
   */
  withCUI?: boolean;
  /**
   * Products context provider wrapper
   *
   * @default true
   */
  withSC?: boolean;
  /**
   * Enables styled components GlobalStyles to use the custom theme
   * from initial state
   */
  colorTheme?: ColorTheme;
  /**
   * Includes the UITheme provider (needed to handle light/dark mode).
   *
   * @default false
   */
  withUITheme?: boolean;
  /**
   * Includes the AuthDialogContext provider.
   *
   * @default true
   */
  withAuthDialog?: boolean;
  /**
   * Controls if you want to send data to channels service
   */
  sendAnalyticsToChannels?: Boolean;
  /**
   * Controls if you want to send window.fbq('init', {})
   */
  sendFacebookPixelInit?: Boolean;
  /**
   * User activity data for page view track
   *
   * If you need to send extra data that can't be sent at the Layout level for
   * example after a query then use the `usePageView` or `trackPageView` from
   * `'Jsx/Analytics/UserActivity'` and set this prop as `null` to be explicit
   * about manually handling it.
   *
   * @default { type: 'page' }
   */
  userActivityPageView?: UsePageViewInput | null;
  /**
   * You should provide a single child to the Layout.
   */
  children: React.ReactChild;
  dontLoadChatScripts?: boolean;
  enableWebPerformanceTracking?: boolean;
  flagClassicShoppingCart?: boolean;
}

const Layout = ({
  client,
  countryCode,
  colorTheme,
  withUITheme,
  sendFacebookPixelInit = true,
  sendAnalyticsToChannels = true,
  withSC = true,
  withCUI = true,
  withAuthDialog = true,
  withSavedCourses = true,
  pageKey,
  crehanaI18n,
  userActivityPageView = {
    type: 'page',
  },
  dontLoadChatScripts,
  children,
  enableWebPerformanceTracking = false,
  flagClassicShoppingCart,
}: LayoutProps) => {
  useTrackWebPerformanceMetrics({
    enabled: enableWebPerformanceTracking,
    page: pageKey,
  });

  const validateSavedCoursesProvider = () =>
    withSavedCourses ? (
      <SavedCoursesProviderWithTheme>{children}</SavedCoursesProviderWithTheme>
    ) : (
      children
    );

  const validateSC = () =>
    withSC ? (
      <ProductsContainer
        flagClassicShoppingCart={!!flagClassicShoppingCart}
        darkModeSupported={withUITheme}
      >
        {validateSavedCoursesProvider()}
      </ProductsContainer>
    ) : (
      validateSavedCoursesProvider()
    );

  const validateAuthDialog = () =>
    withAuthDialog ? (
      <AuthDialogProvider>{validateSC()}</AuthDialogProvider>
    ) : null;

  const validateCUI = () =>
    withCUI ? (
      <CUILayout>
        <CoursePreviewProvider>{validateAuthDialog()}</CoursePreviewProvider>
      </CUILayout>
    ) : (
      validateSC()
    );

  const validateI18N = () => {
    if (crehanaI18n) {
      return (
        <CrehanaI18nProvider {...crehanaI18n}>
          {validateCUI()}
        </CrehanaI18nProvider>
      );
    }
    return validateCUI();
  };

  const validateUserActivity = () => {
    if (userActivityPageView) {
      return (
        <UserActivityWrapper trackData={userActivityPageView}>
          {validateI18N()}
        </UserActivityWrapper>
      );
    }
    return validateI18N();
  };

  return (
    <React.Fragment>
      {colorTheme ? <GlobalStyles colorTheme={colorTheme} /> : null}
      <RootErrorBoundaryWithSentry>
        <ApolloProvider
          client={client || createApolloClient({ pageKey, countryCode })}
        >
          <AuthProvider>
            {sendFacebookPixelInit && <FacebookPixelInit />}
            {sendAnalyticsToChannels && <Channels />}
            <TrackingProvider>
              <AmplitudeLayout>
                {withUITheme ? (
                  <UIThemeProvider>{validateUserActivity()}</UIThemeProvider>
                ) : (
                  validateUserActivity()
                )}
              </AmplitudeLayout>
            </TrackingProvider>
            {!dontLoadChatScripts && <LoadChatScripts />}
          </AuthProvider>
        </ApolloProvider>
      </RootErrorBoundaryWithSentry>

      <CookiesDisabledWarning />
    </React.Fragment>
  );
};

export default Layout;
