import { onError } from '@apollo/client/link/error';

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[Graphql error]: Message: ${message}, Location: ${
          locations ? JSON.stringify(locations) : locations
        }, Path: ${path}`,
      );
    });

  if (operation.operationName === 'createSocketSession') {
    console.log(
      '%c Hey! looks like you have the socket feature activated and because you have not running the server the mutation is going to fail 😥. If you want to disable it just set socketDisable to true in the localStorage.',
      'color: #09f; background: rgba(0,0,0,0.02); padding: 12px 0',
    );
  }

  if (networkError) {
    console.error(`[Network Error]: ${networkError}`);
  }
});

export default errorLink;
