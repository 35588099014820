import { ApolloLink } from '@apollo/client';

import { customLocalStorage, parseQueryStrings } from '@crehana/utils';

const authLink = new ApolloLink((operation, forward) => {
  const newHeaders: Record<string, string> = {};

  const user = customLocalStorage.getItem('user');
  const qs =
    typeof window !== 'undefined'
      ? parseQueryStrings(window.location.search)
      : {};
  const jsonWebToken =
    user && !!JSON.parse(user)
      ? JSON.parse(user).jsonWebToken
      : qs?.current_token;
  const headerAuthorization =
    user && jsonWebToken ? `JWT ${JSON.parse(user).jsonWebToken}` : '';

  if (headerAuthorization) {
    newHeaders.authorization = headerAuthorization;
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...newHeaders,
    },
  }));

  return forward(operation);
});

export default authLink;
