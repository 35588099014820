import React from 'react';

import { useIsClient } from '@crehana/react-hooks';

import getLocalStorageStatus, {
  STORAGE_STATUS,
} from 'Jsx/Utils/getLocalStorageStatus';

const LazyInfo = React.lazy(
  () => import(/* webpackChunkName: "cookies-disabled-info" */ './Info'),
);

const CookiesDisabledWarning = () => {
  const isClient = useIsClient();

  if (!isClient) {
    return null;
  }

  // this code will run only in the client-side thanks to isClient validation
  const localStorageStatus = getLocalStorageStatus();

  if (localStorageStatus === STORAGE_STATUS.AVAILABLE) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <LazyInfo />
    </React.Suspense>
  );
};

export default CookiesDisabledWarning;
