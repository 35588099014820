import { useQuery } from '@apollo/client';

import GET_ORGANIZATION_DATA from './graphql/LearnDashboardOrganizationData.b2b.graphql';

import {
  LearnDashboardOrganizationData,
  LearnDashboardOrganizationDataVariables,
} from './graphql/types/LearnDashboardOrganizationData';

const useOrganizationData = (slug?: string) => {
  const { data, loading, error, refetch } = useQuery<
    LearnDashboardOrganizationData,
    LearnDashboardOrganizationDataVariables
  >(GET_ORGANIZATION_DATA, {
    variables: {
      slug,
    },
    skip: !slug,
    context: { clientName: 'b2b' },
  });

  const organizationData = data?.organizationInfo;

  const hasElevate =
    organizationData?.configuration?.hasStudioAvailable &&
    organizationData?.configuration?.canUploadCourses;

  return { organizationData, loading, error, refetch, hasElevate };
};

export default useOrganizationData;
