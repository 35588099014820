// @ts-nocheck
/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';

import { useMe, Me } from '@crehana/web/dist/ReactContexts/AuthContext';

import { CountryPrefix } from 'Jsx/global.d';

import getCountryPrefix from 'Utils/getCountryPrefix';

import getUserDistinctId from 'Utils/getUserDistinctId';
import parseQueryStrings from 'Utils/parseQueryStrings';

type Payload = {
  query_params?: {
    [key: string]: string | boolean;
  };
  url: string;
  user_id?: number | null;
  distinct_id: string | null;
  referrer: string;
  created_at: string; // "2020-05-04T17:01:00.391000+00:00" (Datetime ISO)
  iso: CountryPrefix | '';
};

const countryPrefix = getCountryPrefix();

export function register(user?: Me) {
  if (typeof window === 'undefined') {
    return;
  }

  const { distinctId } = getUserDistinctId();
  const queryParams = parseQueryStrings(window.location.search);
  const { referrer } = document;

  const payload: Payload = {
    query_params: queryParams,
    url: window.location.href,
    user_id: user?.originalId,
    distinct_id: distinctId,
    referrer,
    // created_at: new Date().toISOString(),
    iso: countryPrefix,
  };

  const isDirect = !referrer;
  const isOrganic =
    referrer &&
    !referrer.includes('crehana.com') &&
    !referrer.includes('creha.co') &&
    !referrer.includes('localhost') &&
    !referrer.includes('127.0.0.1');

  if (isDirect || isOrganic) {
    fetch('/api/v3/channel-register/', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    })
      .then(() => {
        console.log('Success to send data to channels endpoint');
      })
      .catch(error => {
        console.log('Error to send data to channel endpoint', error);
      });
  }
}

// channels
const Channels: FC = ({ children }) => {
  const { me, loading, error } = useMe();

  useEffect(() => {
    if (!loading && !error) {
      register(me);
    }
  }, [loading, me, error]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default Channels;
