/**
 * This link adds a crehana-current-url header in each xhr request
 * to know where are coming from the queries and mutations
 */
import { ApolloLink } from '@apollo/client';

const crehanaCurrentUrl =
  typeof window === 'undefined'
    ? ''
    : `${window.location.pathname}${window.location.search}`;

const crehanaCurrentUrlLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'crehana-current-url': crehanaCurrentUrl,
    },
  }));

  return forward(operation);
});

export default crehanaCurrentUrlLink;
