import type { Me } from '@crehana/web/dist/ReactContexts/AuthContext';

import trackUserActivity from './trackUserActivity';
import {
  PageViewType,
  UserActivityEvent,
  PageIdentifierType,
  PageData,
} from './types';

const trackPageView = ({
  type,
  id,
  page,
  user,
  pageData,
}: {
  type: PageViewType;
  id?: number;
  page?: PageIdentifierType;
  user?: Me;
  pageData?: PageData;
}) => {
  trackUserActivity({
    event: UserActivityEvent.PAGE_VIEW,
    data: {
      item_id: id,
      item_type: type,
      path: window.location.href.split('?')[0]!,
      origin_path: window.document.referrer.split('?')[0]!,
      page_identifier: page,
      page_data: pageData,
    },
    user: user
      ? {
          segment: user.segmentationKey!,
          username: user.username!,
        }
      : undefined,
  });
};

export default trackPageView;
