// @ts-nocheck
import React, { FC, useEffect } from 'react';

import getCountryPrefix from 'Utils/getCountryPrefix';
import { useMe } from 'Jsx/ContextApis/AuthContext';
import { MeAuthContext_me as User } from 'Jsx/ContextApis/AuthContext/graphql/types/MeAuthContext';

export const manualAdvancedMatchingMethod = (user?: User | null) => {
  const email = user?.email || '';
  const firstname = user?.firstName || '';
  const lastName = user?.lastName || '';
  const countryPrefix = getCountryPrefix() || '';
  const advancedMatching = {
    em: email,
    fn: firstname,
    ln: lastName,
    cn: countryPrefix,
  };

  if (window.fbq) {
    window.fbq('init', window.FACEBOOK_APP_ID, advancedMatching);
  }
};

const FacebookPixelInit: FC = ({ children }) => {
  const { me, loading, error } = useMe();

  useEffect(() => {
    if (!loading && !error) {
      manualAdvancedMatchingMethod(me);
    }
  }, [loading, me, error]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default FacebookPixelInit;
