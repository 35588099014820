/* eslint-disable camelcase */
export enum UserActivityEvent {
  PAGE_VIEW = 'page_view',
  PURCHASE = 'purchase',
  SHOPPING_CART = 'shopping_cart',
  CLICK = 'click',
  PLAY_TRAILER = 'trailer',
  COMMENT_COURSE = 'comment',
  CREATE_COURSE_REVIEW = 'review',
  CREATE_PROJECT = 'upload_project',
  ENROLLMENT = 'enrollment',
  RECOMMENDATION_VIEW = 'recommendation_view',
  RECOMMENDATION_POSITION_CLICK = 'recommendation_position_click',
  RECOMMENDATION_POSITION_CHECKOUT = 'recommendation_position_checkout',
  FIRST_PLAY = 'play',
  DOWNLOAD_RESOURCE = 'download',
  READ_ARTICLE = 'read_article',
  CHECKOUT = 'checkout',
  SEARCH_CLICK = 'search_click',
  PRODUCT_PRICING_VIEW = 'product_pricing_view',
}

export interface GeneralTrackData {
  event_uuid: string;
  event_type: UserActivityEvent;
  event_date?: string;
  segment?: number;
  username?: string;
  identifier?: string;
  app_platform: 'web';
  api_version: 2;
  distinct_id: string;
}

export interface UserData {
  segment: number;
  username: string;
}

export interface BaseParams {
  user?: UserData;
  nextUrl?: string;
}

export type PageViewType =
  | 'course'
  | 'tool'
  | 'ebook'
  | 'tutorial'
  | 'membership'
  | 'live'
  | 'custom_bundle'
  | 'bundle'
  | 'project'
  | 'career'
  | 'webinar'
  | 'podcast'
  | 'blog'
  | 'degree'
  | 'page'
  | 'shopping_cart';

export type PageIdentifierType = 'checkout';

export type PageData = {
  currency_code?: string;
  country_code?: string;
  data: { [key: string]: string | number };
};

export interface PageViewParams extends BaseParams {
  event: UserActivityEvent.PAGE_VIEW;
  data: {
    item_id?: number;
    item_type: PageViewType;
    path: string;
    origin_path: string;
    page_identifier?: PageIdentifierType;
    page_data?: PageData;
  };
}

export interface PurchaseParams extends BaseParams {
  event: UserActivityEvent.PURCHASE;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'custom_bundle' | 'course' | 'bundle' | 'membership';
    course_id?: number;
    course_name?: string;
    price: number;
    discount: number;
    order_id: number;
  };
}

export interface ShoppingCartParams extends BaseParams {
  event: UserActivityEvent.SHOPPING_CART;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'course' | 'bundle';
    course_id: number;
    course_name: string;
    price: number;
    discount: number;
  };
}

export interface ClickParams extends BaseParams {
  event: UserActivityEvent.CLICK;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'custom_bundle' | 'course' | 'bundle' | 'membership';
    item_path: string;
  };
}

export interface PlayTrailerParams extends BaseParams {
  event: UserActivityEvent.PLAY_TRAILER;
  data: {
    product_id: number;
    product_name: string;
    duration: number;
    progress: number;
    item_type: 'live' | 'course' | 'webinar';
  };
}

export interface CommentCourseParams extends BaseParams {
  event: UserActivityEvent.COMMENT_COURSE;
  data: {
    product_id: number;
    product_name: string;
    type: 'discussion' | 'reply';
  };
}

export interface ReviewParams extends BaseParams {
  event: UserActivityEvent.CREATE_COURSE_REVIEW;
  data: {
    product_id: number;
    product_name: string;
    score: number;
  };
}

export interface CreateProjectParams extends BaseParams {
  event: UserActivityEvent.CREATE_PROJECT;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'project';
  };
}

export interface EnrollmentParams extends BaseParams {
  event: UserActivityEvent.ENROLLMENT;
  data: {
    product_id: number;
    product_name: string;
    enrollment_date: string;
    enrollment_type: number;
  };
}

export interface RecommendationViewParams extends BaseParams {
  event: UserActivityEvent.RECOMMENDATION_VIEW;
  data: {
    item_type: 'course';
    /* List of course ids separated by comma */
    recommendations: string;
    total_items: number;
    origin:
      | 'home_user'
      | 'course_detail'
      | 'shopping_cart'
      | 'onboarding'
      | 'catalog';
    model_used: string;
  };
}

export interface RecommendationTrackData {
  item_type: 'course';
  item_id: number;
  /* List of course ids separated by comma */
  recommendations: string;
  /* Clicked card position (starting at 1) */
  position_selected: number;
  origin:
    | 'home_user'
    | 'course_detail'
    | 'shopping_cart'
    | 'onboarding'
    | 'catalog';
  model_used: string;
}

export interface RecommendationPositionClickParams extends BaseParams {
  event: UserActivityEvent.RECOMMENDATION_POSITION_CLICK;
  data: RecommendationTrackData;
}

export interface RecommendationPositionCheckoutParams extends BaseParams {
  event: UserActivityEvent.RECOMMENDATION_POSITION_CHECKOUT;
  data: RecommendationTrackData;
}

export interface FirstPlayParams extends BaseParams {
  event: UserActivityEvent.FIRST_PLAY;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'course' | 'trailer' | 'live' | 'tutorial';
  };
}

export interface DownloadResourceParams extends BaseParams {
  event: UserActivityEvent.DOWNLOAD_RESOURCE;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'ebook' | 'tool';
  };
}

export interface ReadArticleParams extends BaseParams {
  event: UserActivityEvent.READ_ARTICLE;
  data: {
    product_id: number;
    product_name: string;
    item_type: 'article';
  };
}
export interface CheckoutParams extends BaseParams {
  event: UserActivityEvent.CHECKOUT;
  data: {
    product_id: number;
    item_type:
      | 'custom_bundle'
      | 'course'
      | 'bundle'
      | 'membership'
      | 'premium'
      | 'premium_duo'
      | 'familiar'
      | 'career'
      | 'shopping_cart';
    course_id: number;
    price?: number;
  };
}

export type SearchClickParams = BaseParams & {
  event: UserActivityEvent.SEARCH_CLICK;
  data: {
    position_selected: number;
    model_used: string;
    product_name: string;
    product_id: number;
    keyword: string;
    item_type: 'course';
  };
};

export type PricingViewParams = BaseParams & {
  event: UserActivityEvent.PRODUCT_PRICING_VIEW;
  data: {
    // Tipo de producto que se está visualizando.
    item_type: 'course' | 'microdegree' | 'bundle' | 'membership_landing';
    // Para el caso de membresía debería estar nulo
    item_id: number | null;
    // Precio en moneda local del curso, requerido cuando item_type no es
    // membership_landing
    price?: number;
    // Precio en moneda local de la membresía individual anual
    price_annual_1?: number;
    // Precio en moneda local de la membresía individual mensual
    price_monthly_1?: number;
    // Precio en moneda local  de la membresía duo anual
    price_annual_2?: number;
    // Precio en moneda local  de la membresía duo mensual
    price_monthly_2?: number;
    // Precio en moneda local  de la membresía familiar anual
    price_annual_3?: number;
    // Precio en moneda local  de la membresía familiar mensual
    price_monthly_3?: number;
    // Código del pais de la moneda
    currency_code:
      | 'PEN'
      | 'ARS'
      | 'MXN'
      | 'COP'
      | 'EUR'
      | 'CLP'
      | 'BRL'
      | 'USD';
  };
};

export type TrackUserActivity = (
  params:
    | PageViewParams
    | PurchaseParams
    | ShoppingCartParams
    | ClickParams
    | PlayTrailerParams
    | CommentCourseParams
    | ReviewParams
    | CreateProjectParams
    | EnrollmentParams
    | RecommendationPositionClickParams
    | RecommendationPositionCheckoutParams
    | RecommendationViewParams
    | FirstPlayParams
    | DownloadResourceParams
    | CheckoutParams
    | SearchClickParams
    | PricingViewParams,
) => Promise<{
  status: 'success' | 'error' | 'skipped';
  message?: string;
}>;

export interface UsePageViewInput {
  type: PageViewType;
  id?: number;
  page?: PageIdentifierType;
  pageData?: PageData;
}

export type Membership = {
  prices: {
    total: number;
    price: number;
    currencyCode: 'PEN' | 'ARS' | 'MXN' | 'COP' | 'EUR' | 'CLP' | 'BRL' | 'USD';
  };
  // This FREE_PLAN is temporal here to prevent ts-lint errors, this should be refactored soon
  tag: 'FREE_PLAN' | 'FAMILIAR' | 'PREMIUM_DUO' | 'PREMIUM';
};

export type CourseData = {
  originalId: number;
  prices: {
    price: number;
    currencyCode: 'PEN' | 'ARS' | 'MXN' | 'COP' | 'EUR' | 'CLP' | 'BRL' | 'USD';
  };
};
