import { useEffect } from 'react';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';
import { useIsBrazil } from '@crehana/i18n';
// import { useIsPlayRoomActivated } from '@crehana/compass.playroom';

import loadChatScripts from 'Jsx/Utils/loadChatScripts';

import useOrganizationData from '../../Hooks/useOrganizationData';

/** Just a helper component to access to the authContext.me data and load the salesforce scripts */
const LoadChatScripts = () => {
  const { me, loading } = useMe();
  const isBr = useIsBrazil();
  const organizationSlug = me?.organization?.slug;
  // TODO: Uncomment when playroom is ready
  // const {
  //   isActivated: isPlayroomActivated,
  //   loading: loadingPlayroom,
  //   error: playroomError,
  // } = useIsPlayRoomActivated(organizationSlug);
  const isPlayroomActivated = false;
  const loadingPlayroom = false;
  const playroomError = undefined;
  const { hasElevate } = useOrganizationData(organizationSlug);

  useEffect(() => {
    if (!loading || !loadingPlayroom) {
      try {
        loadChatScripts(me, isBr, isPlayroomActivated, !!hasElevate);
      } catch (err) {
        console.error(err || playroomError);
      }
    }
  }, [
    me,
    loading,
    isBr,
    isPlayroomActivated,
    loadingPlayroom,
    playroomError,
    hasElevate,
  ]);

  return null;
};

export default LoadChatScripts;
