/* eslint-disable camelcase */
import type { CSSProp } from 'styled-components';

import type { CuiTheme, DialogProps } from '@crehana/ui';
import type { I18nTranslation } from '@crehana/i18n';

import { UserType } from 'Jsx/Types/UserType';
import { ScormAPI21, ScormAPI2004 } from 'Jsx/Types/ScormApi';

import { LoginRegisterSwitcherProps } from './Auth/LoginRegisterSwitcher/LoginRegisterSwitcher';

declare module 'styled-components' {
  export interface DefaultTheme extends CuiTheme {}
}

declare module 'react' {
  interface Attributes {
    css?: CSSProp<CuiTheme>;
  }
}

declare global {
  namespace JSX {
    interface IntrinsicAttributes {
      css?: CSSProp<CuiTheme>;
    }
  }
}

export type ABTestValues = 'A' | 'B';

export type CountryPrefix =
  | 'mx'
  | 'es'
  | 'pe'
  | 'co'
  | 'ec'
  | 'br'
  | 'ar'
  | 'cl'
  | '';

export type SubCategory = {
  id: number;
  name: string;
  url: string;
  slug: string;
  order: number;
  coursesNumber?: number;
};
export type SubcategoryType = SubCategory;

export interface Category {
  id: number;
  url: string;
  name: string;
  slug: string;
  order: number;
  subcategories: SubCategory[];
  coursesNumber?: number;
}
/** @deprecated DON'T use this type, use the Category */
export type CategoryType = Category;

export type Software = {
  id: number;
  name: string;
  order: number;
  slug: string;
  url: string;
};
export type SoftwareType = Software;

export interface Level {
  id: number;
  name: string;
  slug: string;
}

// i18n types
export enum LANGUAGES {
  ES = 'es',
  PT = 'pt',
  EN = 'en',
}

export type Translation = {
  language: LANGUAGES;
  page: string;
  keys: Array<{
    name: string;
    value: string;
  }>;
};

export type TranslationType = Translation;

export type ABTests = {
  [key: string]: ABTestValues;
};

export type AppProperties = {
  edges: {
    node: {
      key: string;
      originalId: number;
      uniqueKey: string;
      value: string;
    };
  }[];
};

export type FeatureFlags = {
  is_active: boolean;
  key: string;
}[];

export type KeysCrehanaUrls = {
  blog: string;
  bundles: string;
  careers: string;
  categories: Category[];
  courses_all: string;
  courses_new: string;
  gifts: string;
  lives: string;
  membership: string;
  my_courses: string;
  projects: string;
  resources: string;
  scholarships: string;
  softwares: Software[];
  stories: string;
};

export type MetaTags = {
  description: string;
  title: string;
  extra_kwargs: {
    [key: string]: string;
  };
  image: string;
  extra: any[];
  url: string;
  /* Protocol and domain */
  protocol_host: string;
  /* The full url: Protocol + domain + path */
  canonical_url: string;
  robots_content?: any;
};

export type ColorTheme = {
  primary?: ColorThemeLevels;
  secondary?: ColorThemeLevels;
};

export type ColorThemeLevels = {
  darker?: string;
  dark?: string;
  main?: string;
  medium?: string;
  light?: string;
  lighter?: string;
};

export interface CrehanaApp {
  is_coupon_campaign?: boolean;
  ab_tests: ABTests;
  app_properties: AppProperties;
  feature_flags: FeatureFlags;
  keys_crehana_urls: KeysCrehanaUrls;
  user: UserType | null;
  LANGUAGE_KEY: LANGUAGES;
  translation_keys: Translation[];
  country_prefix: CountryPrefix;
  metaTags: MetaTags;
  color_theme?: ColorTheme;
}

export enum AuthProvider {
  FACEBOOK = 'facebook',
  GOOGLE = 'google-oauth2',
  LINKEDIn = 'linkedin',
  TWITTER = 'twitter',
}
export interface __INITIAL_STATE__TYPE {
  isFullPassMembership?: boolean;
  showMarathonViralLoop?: boolean;
  facebook_auth_hide?: boolean;
  facebook_auth_link?: boolean;
  ab_tests: ABTests;
  app_properties: AppProperties;
  content: {
    edges: {
      node: {
        key: string;
        text: string;
        url?: string;
        urlRedirect?: string;
      };
    }[];
  };
  feature_flags: FeatureFlags;
  keys_crehana_urls: KeysCrehanaUrls;
  user: UserType | null;
  LANGUAGE_KEY: LANGUAGES;
  translation_keys: Translation[] | I18nTranslation;
  oc_expired: boolean;
  tolerance_date: string;
  flag_b2b_payments: boolean;
  organization_currency: string;
  pusherOptions: {
    cluster?: string;
    key: string;
    ssl: boolean;
    forceTLS?: boolean;
  };
  uploadFile: string;
  COUNTRY_PREFIX?: CountryPrefix;
  country_prefix?: CountryPrefix;
  skill_iq: {
    skill_status: number;
    skill_active: boolean;
    skill_user: number;
    skill_expired: boolean;
  };
  show_skill_module: boolean;
  flag_b2b_download_new_report: boolean;
  // New key for the crehana app (spa)
  crehanaApp?: CrehanaApp;
  color_theme?: ColorTheme;
  percentageDiscount?: {
    daysLeft: number;
    discountType: 'new-user';
    total: string;
  };
  can_select_team?: boolean;
  informate_course_launch?: boolean;
  surveyIsAvailable?: boolean;
  Settings?: {
    me: {
      username: string;
      email: string;
      subscription?: {
        payment: string;
        membershipType: string;
        creditCard: string;
        billingCycle: string;
        availableLicenses: number;
        familyRole: string;
        expiryDate: string;
        renovation: boolean;
      };
      membership?: {
        isSubscriptionCanceled: boolean;
        originalId: number;
        membershipType: {
          months: number;
          tag: string;
          name: string;
          licenseNumber: number;
        };
        isUpsell: boolean;
        monthsRemaining: string;
        paymentMethod: string;
      };
    };
    last_membership_type_id: number | null;
    membership_type?: {
      relevant: boolean;
      subtitle?: any;
      name: string;
      originalId: number;
      price: number;
      months: number;
      urlCheckout: string;
      benefitsV2: {
        text: string;
        originalId: number;
      }[];
      isRenovation: boolean;
      prices: {
        yearlySavings?: any;
        realPrice: number;
        currencyCode: string;
        price: number;
        discount: number;
        currencySymbol: string;
        monthlyPrice: number;
        total: number;
        yearlyPrice: number;
      };
      benefits: {
        text: string;
      }[];
      tag: string;
      slug: string;
      isMobile: boolean;
    };
  };
  membershipLandingData?: {
    titleColor?: string;
    sectionBackgroundColor?: string;
    membershipCardColors?: {
      isDarkBackground: boolean;
    };
    backgroundImages?: {
      desktop: string;
    };
    heroData?: {
      title: string;
      subtitle: string;
      buttonText: string;
      buttonLabel: string;
      backgroundImages?: {
        desktop: string;
      };
    };
  };
  membershipsPlanOrderKey?: string;
  membershipsPlanTagKey?: string;
}

type RequestIdleCallbackHandle = any;
type RequestIdleCallbackOptions = {
  timeout: number;
};
type RequestIdleCallbackDeadline = {
  readonly didTimeout: boolean;
  timeRemaining: () => number;
};

type GoogleAPIAttachClickHandler = (
  el: HTMLDivElement,
  config: any,
  callback: (googleUser: any) => void,
  callbackError: (error: any) => void,
) => void;

type GoogleAPI = {
  load: (type: 'auth2', callback: () => void) => void;
  auth2: {
    init: (config: {
      client_id: string | number;
      cookiepolicy: 'single_host_origin';
    }) => {
      attachClickHandler: GoogleAPIAttachClickHandler;
    };
  };
};

// The items for the gtag event.
export interface GtagItem {
  id: string;
  name?: string;
  brand?: string;
  category?: string;
  coupon?: string;
  list_name?: string;
  list_position?: number;
  price?: number;
  quantity?: number;
  variant?: string;
}

declare global {
  interface Window {
    STATIC_URL?: string;
    /**
     * Indicates the commit hash for this release
     * In dev mode it's defined as 'dev'
     */
    APP_RELEASE?: string;
    /**
     * Useful to check if there is already a mounted dialog.
     * Use it to don't open a modal over another modal
     */
    dialogMounted?: boolean;
    /* used to hide th chats (zoopim & intercom) */
    PAGE_KEY?: 'HOMEPAGE';
    HIDE_CHAT?: boolean;
    feature_flags: FeatureFlags;
    // global helpers to show a navigation-loader in the top of the page
    // it only exists in the crehana-app project (SPA)
    startPageTransition: () => void;
    finishPageTransition: () => void;
    // the main chunk file for each page, use it to preload the chunk before render the page
    mainChunkFile?: string;
    dataLayer?: any[];
    // third party scripts
    /**
     * Keep in mind that gtag can send tracks to google-analytics too
     * To do that you need to specify the APP ID in the payload.send_to field
     * */
    gtag?: (
      category: 'event' | 'set' | 'get' | 'config' | 'js',
      eventName:
        | string
        | {
            country?: string;
            currency?: string;
          }
        | Date,
      payload?: {
        /** used to specify the APP ID to send the track */
        send_to?: string;
        event_category?: string;
        event_label?: string;
        value?: number;
        items?: GtagItem[];
        promotions?: GtagItem[];
        page_location?: string;
        event_callback?: () => void;
        checkout_step?: number;
        checkout_option?: string;
      },
    ) => void;
    ga?: (
      action: 'send' | 'create' | string,
      category:
        | string
        | {
            id?: string;
            name?: string;
            price?: number;
          },
      eventName?: string | { name?: string },
      data?: any,
      description?: string,
    ) => void;
    fbq?: (track: string, event: string, payload?: any) => void;
    Sentry: {
      captureMessage: any;
      configureScope(
        func: (scope: {
          setUser(user: { email: string | null; id?: number | string }): void;
        }) => void,
      ): void;
      captureException: (error: any) => number;
      withScope: (scope: any) => void;
      showReportDialog(options?: {
        eventId?: number;
        title?: string;
        subtitle?: string;
        subtitle2?: string;
      }): void;
      setTag(tag: string, source: string): void;
    };
    GLOBAL_GOOGLE_CLIENT_ID: string;
    // end third party scripts

    /**
     * Force close login/register(Don't leave notification) floating notification
     */
    forceDontLeaveNotificationToHide?(): void;

    __forceSmoothScrollPolyfill__?: boolean;
    __INITIAL_STATE__: __INITIAL_STATE__TYPE;
    __INITIAL_STATE_UNPARSED__?: string;
    __SITE_URL__: string;
    /**
     * Function to open the login modal
     * @deprecated Use import { useAuthDialog } from 'Jsx/ContextApis/AuthDialog'
     */
    showLoginModal(
      nextUrl?: string | null,
      dialogProps?: DialogProps,
      loginRegisterSwitcherProps?: Partial<LoginRegisterSwitcherProps>,
    ): void;
    /**
     * Function to open the register modal
     * @deprecated Use import { useAuthDialog } from 'Jsx/ContextApis/AuthDialog'
     */
    showRegisterModal(
      nextUrl?: string | null,
      dialogProps?: DialogProps,
      loginRegisterSwitcherProps?: Partial<LoginRegisterSwitcherProps>,
      originComponent?: string,
    ): void;
    /**
     * Function to close the auth modal
     * @deprecated Use import { useAuthDialog } from 'Jsx/ContextApis/AuthDialog'
     */
    closeAuthenticateModal: () => void;
    /**
     * Useful to open/close the Main menu search modal
     */
    mainMenuToggleSearch?: () => void;
    nextUrl: null | string;

    // custom global functions (deprecated)
    showCoursePreview?: (course: any) => void;

    // experimental apis
    requestIdleCallback?: (
      callback: (deadline: RequestIdleCallbackDeadline) => void,
      opts?: RequestIdleCallbackOptions,
    ) => RequestIdleCallbackHandle;
    cancelIdleCallback?: (handle: RequestIdleCallbackHandle) => void;
    /**
     * Useful to check if the distinct user id has been recenlty created
     */
    distinctUserIdWasRecentlyCreated?: boolean;
    google_optimize?: {
      get: (experimentId: string) => string | undefined;
    };

    // SCORM api adapters
    API: ScormAPI21;
    API_1484_11: ScormAPI2004;

    APP_DEBUG?: boolean;
    AMPLITUDE_API_KEY: string;
    GOOGLE_ANALYTICS_ENABLED?: boolean;
    FACEBOOK_APP_ID: string;
    facebookQueue?: [];
    __PAGE_DATA__: {
      COUNTRY_PREFIX?: CountryPrefix;
    };
    MercadoPago?: any;
    MERCADOPAGO_PUBLIC_KEY: string;
    openSnackbar: (
      message: string,
      error: boolean,
      children?: React.ReactNode,
    ) => void;
  }
}
