/**
 * Esta función sirve como base de initialState que usa cada entry.
 * Aquí se obtiene la data en común que todas las páginas deben tener.
 * Ahora mismo solo obtenemos las urls de categorías/subCategorias.
 *
 * Recordar que esta función corre en el SSR
 * por lo que no debemos hacer uso de `window` aquí.
 * Si necesitamos usar algun valor de `window`
 * esta puede ser pasada en el argumento data.
 */

import {
  transformInitialStateTranslations,
  I18nProviderProps,
  I18nTranslation,
  Namespaces as I18nNamespaces,
} from '@crehana/i18n';

import { UserType } from 'Jsx/Types/UserType';
import {
  __INITIAL_STATE__TYPE,
  CategoryType,
  SoftwareType,
  Category,
  SubCategory,
  Software,
  LANGUAGES,
  ColorTheme,
  CountryPrefix,
} from 'Jsx/global.d';

export interface CategoryFormatted extends Category {
  subCategories: SubCategory[];
}

export type KeysCrehanaUrlsType = {
  blog: string;
  scholarships: string;
  lives: string;
  resources: string;
  bundles: string;
  courses_all: string; //eslint-disable-line
  gifts: string;
  membership: string;
  stories: string;
  careers: string;
  courses_new: string; //eslint-disable-line
  categories: CategoryType[];
  projects: string;
  softwares: SoftwareType[];
};

export type InitialStateResult<TData> = TData & {
  categories: CategoryFormatted[];
  softwares: Software[];
  crehanaI18n?: I18nProviderProps;
  colorTheme?: ColorTheme;
  countryPrefix: CountryPrefix | '';
};

export type GetInitialStateInner<TData, TRawData = {}> = (
  data: TRawData,
  user: UserType | null,
  fullInitialState: __INITIAL_STATE__TYPE,
  other?: any,
) => TData & {
  categories?: CategoryFormatted[];
  softwares?: Software[];
};

export type GetInitialStateEnhanced<TData, TRawPageData> = (
  data: TRawPageData,
  user: UserType | null,
  fullInitialState: __INITIAL_STATE__TYPE,
  other?: any,
) => InitialStateResult<TData>;

export type Options = {
  i18nConfig?: {
    pageKey: string;
    defaults: I18nNamespaces;
    fallbackNS?: string[];
  };
};

function getBaseInitialState<TData = any, TRawPageData = {}>(
  getInitialState: GetInitialStateInner<TData, TRawPageData>,
  options: Options = {},
): GetInitialStateEnhanced<TData, TRawPageData> {
  return function getInitialStateEnhanced(
    data,
    user,
    fullInitialState,
    ...rest
  ) {
    // ================= i18n stuff ================ //
    const countryCode = (
      fullInitialState.country_prefix ||
      fullInitialState.COUNTRY_PREFIX ||
      ''
    ).toLowerCase() as CountryPrefix | '';
    const i18nData: {
      crehanaI18n?: I18nProviderProps;
    } = {};

    if (options.i18nConfig) {
      const { pageKey, defaults, fallbackNS } = options.i18nConfig;
      const translations: I18nTranslation = Array.isArray(
        fullInitialState.translation_keys,
      )
        ? transformInitialStateTranslations(fullInitialState.translation_keys)
        : fullInitialState.translation_keys;

      i18nData.crehanaI18n = {
        languageCode: fullInitialState.LANGUAGE_KEY
          ? (fullInitialState.LANGUAGE_KEY.toLowerCase() as LANGUAGES)
          : LANGUAGES.ES,
        translations,
        defaultTranslations: {
          [LANGUAGES.ES]: {
            [pageKey]: defaults.es || {},
          },
          [LANGUAGES.PT]: {
            [pageKey]: defaults.pt || {},
          },
          [LANGUAGES.EN]: {
            [pageKey]: defaults.en || {},
          },
        },
        defaultNs: pageKey,
        fallbackLanguageCode: LANGUAGES.ES,
        fallbackNs: ['main-menu', ...(fallbackNS || [])],
        countryCode,
      };
    }
    // ========== end i18n ========== //

    const fnData = getInitialState(data, user, fullInitialState, ...rest);

    if (process.env.NODE_ENV === 'development') {
      if (!fullInitialState) {
        console.error(
          'Hey! Looks like you are not passing the third argument to the getInitialState function. You should use the function like this <getInitialState(data, user, window.__INITIAL_STATE__)>',
        );
      }

      if (fnData.categories) {
        console.error(`
  Hey! Your <getInitialState> function is returning an object with a <categories> key
  but the <getBaseInitialState> function is re-writing this. Are you sure it is ok?
        `);
      }
      if (fnData.softwares) {
        console.error(`
  Hey! Your <getInitialState> function is returning an object with a <softwares> key
  but the <getBaseInitialState> function is re-writing this. Are you sure it is ok?
        `);
      }
    }

    const categories = fullInitialState.keys_crehana_urls?.categories.map(
      category => ({
        ...category,
        subCategories: category.subcategories,
      }),
    );

    return {
      countryPrefix: countryCode,
      ...fnData,
      categories,
      softwares: fullInitialState.keys_crehana_urls?.softwares,
      ...i18nData,
      colorTheme: fullInitialState.color_theme,
    };
  };
}

export default getBaseInitialState;
