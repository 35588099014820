import * as React from 'react';
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals/base';

import injectPolyfills from './web-vitals-polyfill';

// import roundWithPrecision from 'crehana-utils/roundWithPrecision';

const isProduction = process.env.NODE_ENV === 'production';

type UseTrackWebPerformanceMetricsOptions = {
  enabled: boolean;
  page?: string;
};

export const useTrackWebPerformanceMetrics = ({
  enabled,
  page,
}: UseTrackWebPerformanceMetricsOptions) => {
  React.useEffect(() => {
    /** TEMPORAL IMPLEMENTATION
     * once it has been tested in production we'll move this code to @crehana/react-hooks
     */
    const { hostname } = window.location;
    const isAuthorizedDomain =
      hostname === 'www.crehana.com' || hostname === 'qa.creha.co';

    if (isProduction && isAuthorizedDomain && enabled) {
      injectPolyfills();

      const sendToPerformanceApi = (options: {
        name: string;
        value: number;
      }) => {
        // you can use this url to test in localhost
        // const urlDev = 'http://localhost:8000/api/v3/performance/';
        const url =
          hostname === 'www.crehana.com'
            ? 'https://www.crehana.com/api/v3/performance/'
            : 'https://qa.creha.co/api/v3/performance/';
        const body = JSON.stringify([
          {
            event_name: options.name.toLowerCase(),
            // value: roundWithPrecision(options.value, 4),
            value: options.value,
            page: page || 'unknow',
            current_url: window.location.href.split('?')[0],
          },
        ]);

        const sendDataWithFetch = () =>
          fetch(url, {
            body,
            method: 'POST',
            keepalive: true,
          });

        try {
          if (navigator?.sendBeacon) {
            try {
              const headers = {
                type: 'application/json',
              };
              const blob = new Blob([body], headers);

              navigator.sendBeacon(url, blob);
            } catch {
              // fallback to fetch api
              sendDataWithFetch();
            }
          } else {
            // fallback to fetch api
            sendDataWithFetch();
          }
        } catch {
          // just ignore the error
        }
      };

      getCLS(sendToPerformanceApi);
      getFCP(sendToPerformanceApi);
      getFID(sendToPerformanceApi);
      getLCP(sendToPerformanceApi);
      getTTFB(sendToPerformanceApi);
    }
  }, [enabled, page]);
};
