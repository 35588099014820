import { useEffect, useRef } from 'react';

import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

import { UsePageViewInput } from './types';
import trackPageView from './trackPageView';

const usePageView = ({ type, id, page, pageData }: UsePageViewInput) => {
  const { me, loading, error } = useMe();
  const tracked = useRef(false);

  useEffect(() => {
    if (!tracked.current && !loading && !error) {
      trackPageView({ type, id, page, user: me, pageData });
      tracked.current = true;
    }
  }, [me, loading, error]);
};

export default usePageView;
