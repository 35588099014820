import { ApolloLink } from '@apollo/client';

const appReleaseLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'creh-app-version':
        typeof window !== 'undefined' ? window.APP_RELEASE : '',
    },
  }));

  return forward(operation);
});

export default appReleaseLink;
