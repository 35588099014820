/* eslint-disable no-nested-ternary */
import {
  createGlobalStyle,
  ThemedStyledProps,
  DefaultTheme,
} from 'styled-components';

import { ColorTheme, ColorThemeLevels } from '@crehana/ts-types';

interface GlobalStylesProps {
  colorTheme: ColorTheme;
}

function getCustomThemeColor(
  type: keyof ColorTheme,
  level: keyof ColorThemeLevels,
): (props: ThemedStyledProps<GlobalStylesProps, DefaultTheme>) => string {
  return ({ colorTheme }) => {
    if (colorTheme) {
      const selectedType = colorTheme[type];

      // Select the color by the given level or
      // fallback to main color if it exists.
      return selectedType && selectedType[level]
        ? (selectedType[level] as string)
        : selectedType && selectedType.main
        ? selectedType.main
        : '';
    }
    return '';
  };
}

const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  body {
    --color-primary-dark: ${getCustomThemeColor('primary', 'dark')};
    --color-primary-main: ${getCustomThemeColor('primary', 'main')};
    --color-primary-light: ${getCustomThemeColor('primary', 'light')};
    --color-primary-lighter: ${getCustomThemeColor('primary', 'lighter')};

    --color-secondary-dark: ${getCustomThemeColor('secondary', 'dark')};
    --color-secondary-main: ${getCustomThemeColor('secondary', 'main')};
    --color-secondary-light: ${getCustomThemeColor('secondary', 'light')};
    --color-secondary-lighter: ${getCustomThemeColor('secondary', 'lighter')};
  }
`;

export default GlobalStyles;
