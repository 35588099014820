import React from 'react';

import { AmplitudeProvider } from '@crehana/analytics';
import { useMe } from '@crehana/web/dist/ReactContexts/AuthContext';

const AmplitudeLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { me, loading } = useMe();

  return (
    <AmplitudeProvider
      apiKey={typeof window !== 'undefined' ? window.AMPLITUDE_API_KEY : ''}
      user={{
        isLoading: loading,
        data: me,
      }}
    >
      {children}
    </AmplitudeProvider>
  );
};

export default AmplitudeLayout;
