// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '@crehana/ui';

const Cat = styled.div`
  background-position: center;
  background-size: cover;
  width: 131px;
  height: 190px;
  ${breakpoints.up('sm')} {
    width: 250px;
    height: 350px;
  }
  background-image: url('/static/img/crehana-cat-in-jar.png');
`;

const Container = styled.div`
  max-width: 600px;
  ${breakpoints.up('md')} {
    max-width: 700px;
  }
`;

class RootErrorBoundaryWithSentry extends React.Component<
  {},
  { eventId?: number; hasError: boolean }
> {
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      eventId: undefined,
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });

    // just in case Sentry has not loaded yet
    if (window.Sentry) {
      window.Sentry.withScope(scope => {
        scope.setTag('error_type', 'critical');
        scope.setTag('error_origin', 'root_error_boundary');
        scope.setExtras(errorInfo);
        const eventId = window.Sentry.captureException(error);

        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex justify-center items-center w-full h-screen p-12">
          <Container className="w-full h-full flex flex-col md:flex-row sm:h-auto">
            <Cat className="flex-shrink-0" />
            <div
              className="tw-font-pop mt-12 md:mt-0"
              style={{ lineHeight: '1.5' }}
            >
              <h2
                style={{
                  fontSize: 24,
                }}
              >
                Oops, tenemos un problema...
              </h2>
              <br />
              <p className="mt-4 text-16">
                Nuestro equipo acaba de ser informado y ya está trabajando para
                solucionarlo. Si quieres ayudar, cuéntanos qué ha pasado a
                continuación.
              </p>
              <br />
              {window.Sentry && (
                <button
                  type="button"
                  className="inline-flex items-center justify-center focus:outline-none h-auto md:h-48 px-40 py-8 md:py-0 cursor-pointer bg-purple-main text-white hover:bg-secondary-main tw-font-semibold w-full sm:w-auto"
                  onClick={() =>
                    window.Sentry.showReportDialog({
                      eventId: this.state.eventId,
                      title: 'Oops, tenemos un problema...',
                      subtitle2:
                        'Si quieres ayudar, cuéntanos qué ha pasado a continuación.',
                    })
                  }
                >
                  Brindar detalles del error
                </button>
              )}
              <br />
              <button
                onClick={() => window.location.reload()}
                className="p-8 mt-12 text-secondary-main"
                type="button"
              >
                O puedes intentar recargando la página.
              </button>
            </div>
          </Container>
        </div>
      );
    }
    return <div>{this.props.children}</div>;
  }
}

export default process.env.NODE_ENV === 'production'
  ? RootErrorBoundaryWithSentry
  : ({ children }) => children;
