import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import { CountryCode } from '@crehana/ts-types';

import {
  BASE_API_URL,
  APOLLO_CLIENT_DOMAIN,
  CREDENTIALS,
  GRAPH_ENDPOINT_SEARCH,
  GRAPH_ENDPOINT_B2B,
  GRAPH_ENDPOINT_V4_B2B,
  GRAPH_ENDPOINT_V4_STAFF,
  GRAPH_ENDPOINT_PRIVATE,
  GRAPH_ENDPOINT_PAYMENTS,
  GRAPH_ENDPOINT_PAYMENTS_V2,
  GRAPH_ENDPOINT_V3,
  GRAPH_ENDPOINT_V4,
  GRAPH_ENDPOINT_V5,
  GRAPH_ENDPOINT_AUTH,
} from '../CONSTANTS';

import errorLink from './Links/errorLink';
import csrfTokenLink from './Links/csrfTokenLink';
import authConfig from './Links/authLink';
import currencyLink from './Links/currencyLink';
import appReleaseLink from './Links/appReleaseLink';
import operationNameLink from './Links/operationNameLink';
import distinctId from './Links/distinctId';
import crehanaCurrentUrlLink from './Links/crehanaCurrentUrlLink';

function createIsomorphLink() {
  const common = { credentials: CREDENTIALS };

  const mainLink = createHttpLink({
    uri: BASE_API_URL,
    ...common,
  });

  const privateLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'private',
    createHttpLink({
      uri: GRAPH_ENDPOINT_PRIVATE,
      ...common,
    }),
    mainLink,
  );

  const authLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'auth',
    createHttpLink({
      uri: GRAPH_ENDPOINT_AUTH,
      ...common,
    }),
    privateLink,
  );

  const v4Link = ApolloLink.split(
    operation => operation.getContext().clientName === 'v4',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V4,
      ...common,
    }),
    authLink,
  );

  const v3Link = ApolloLink.split(
    operation => operation.getContext().clientName === 'v3',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V3,
      ...common,
    }),
    v4Link,
  );

  const paymentsLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'payments',
    createHttpLink({
      uri: GRAPH_ENDPOINT_PAYMENTS,
      ...common,
    }),
    v3Link,
  );

  const paymentsV2Link = ApolloLink.split(
    operation => operation.getContext().clientName === 'paymentsv2',
    createHttpLink({
      uri: GRAPH_ENDPOINT_PAYMENTS_V2,
      ...common,
    }),
    paymentsLink,
  );

  const b2bLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'b2b',
    createHttpLink({
      uri: GRAPH_ENDPOINT_B2B,
      ...common,
    }),
    paymentsV2Link,
  );

  const v4b2bLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'v4.b2b',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V4_B2B,
      ...common,
    }),
    b2bLink,
  );

  const v4staffLink = ApolloLink.split(
    operation => operation.getContext().clientName === 'v4.staff',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V4_STAFF,
      ...common,
    }),
    v4b2bLink,
  );

  // v5 - Gamification API Schema
  const v5Link = ApolloLink.split(
    operation => operation.getContext().clientName === 'v5',
    createHttpLink({
      uri: GRAPH_ENDPOINT_V5,
      ...common,
    }),
    v4staffLink,
  );

  const schemaLinks = ApolloLink.split(
    operation => operation.getContext().clientName === 'search',
    createHttpLink({
      uri: GRAPH_ENDPOINT_SEARCH,
      ...common,
    }),
    v5Link,
  );

  return schemaLinks;
}

type CreateApolloClientOptions = {
  pageKey?: string;
  countryCode?: CountryCode;
};

const createApolloClient = ({
  pageKey,
  countryCode,
}: CreateApolloClientOptions = {}) => {
  const cache = new InMemoryCache({
    typePolicies: {
      PageSetupNode: {
        keyFields: [],
      },
    },
  });

  // @ts-expect-error typescript can't detect that we are filtering this
  const links: ApolloLink[] = [
    errorLink,
    // when using a custom domain, do not send our custom headers to avoid CORS errors
    !APOLLO_CLIENT_DOMAIN && csrfTokenLink,
    !APOLLO_CLIENT_DOMAIN && distinctId,
    !APOLLO_CLIENT_DOMAIN && crehanaCurrentUrlLink,
    authConfig,
    !APOLLO_CLIENT_DOMAIN && currencyLink(countryCode),
    !APOLLO_CLIENT_DOMAIN && appReleaseLink,
    !APOLLO_CLIENT_DOMAIN && operationNameLink(pageKey),
    createIsomorphLink(),
  ].filter(Boolean);

  const client = new ApolloClient({
    ssrMode: false,
    cache,
    link: ApolloLink.from(links),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });

  return client;
};

export default createApolloClient;
