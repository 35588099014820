/* eslint-disable @typescript-eslint/no-shadow */
// @ts-nocheck
/* eslint-disable no-restricted-globals, no-restricted-properties */

export enum STORAGE_STATUS {
  FULL = 'full',
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
}

function getLocalStorageStatus(): STORAGE_STATUS {
  const test = 'test';

  try {
    // try setting an item
    localStorage.setItem('test', test);
    localStorage.removeItem('test');
  } catch (e) {
    // browser specific checks if local storage was exceeded
    if (
      e.name === 'QUATA_EXCEEDED_ERR' || // Chrome
      e.name === 'NS_ERROR_DOM_QUATA_REACHED' // Firefox/Safari
    ) {
      // local storage is full
      return STORAGE_STATUS.FULL;
    }
    try {
      if (localStorage.remainingSpace === 0) {
        // IE
        // local storage is full
        return STORAGE_STATUS.FULL;
      }
    } catch (e) {
      // localStorage.remainingSpace doesn't exist
    }

    // local storage might not be available
    return STORAGE_STATUS.UNAVAILABLE;
  }
  return STORAGE_STATUS.AVAILABLE;
}

export default getLocalStorageStatus;
