import React from 'react';

import { useUITheme } from 'Jsx/ContextApis/UITheme';

import BasicSavedCoursesProvider from './BasicSavedCoursesProvider';

/**
 * Requerments:
 * - I18Context
 * - AuthContext
 * - UIThemeContext
 */
const SavedCoursesProviderWithTheme: React.FC = ({ children }) => {
  const { isDark } = useUITheme();

  return (
    <BasicSavedCoursesProvider isDark={isDark}>
      {children}
    </BasicSavedCoursesProvider>
  );
};

export default SavedCoursesProviderWithTheme;
