// @ts-nocheck
/**
 * Hey! It's not recommended to use this function!
 * Use it when you can't get the country prefix from the top of your components.
 */
import { CountryPrefix } from 'Jsx/global.d';

function getCountryPrefix(): CountryPrefix {
  if (typeof window === 'undefined') {
    return '';
  }

  const countryPrefixFromInitialState = window.__INITIAL_STATE__
    ? window.__INITIAL_STATE__.COUNTRY_PREFIX
    : '';
  const countryPrefixFromPageData = window.__PAGE_DATA__
    ? (window.__PAGE_DATA__.COUNTRY_PREFIX as string)
    : '';

  return (
    countryPrefixFromInitialState ||
    countryPrefixFromPageData ||
    ''
  ).toLowerCase() as CountryPrefix;
}

export default getCountryPrefix;
