export const APOLLO_CLIENT_DOMAIN = process.env.APOLLO_CLIENT_DOMAIN || '';
export const API_FROM_GLOBAL =
  // @ts-expect-error crehanaApiUrl is a global variable but it's not typed
  typeof window !== 'undefined' && window.crehanaApiUrl;

export const GRAPH_ENDPOINT = `${APOLLO_CLIENT_DOMAIN}/api/v2/graph/`;
console.log({GRAPH_ENDPOINT})

export const GRAPH_ENDPOINT_AUTH = `${APOLLO_CLIENT_DOMAIN}/api/v2/graph/auth/`;

export const GRAPH_ENDPOINT_PAYMENTS = `${APOLLO_CLIENT_DOMAIN}/api/v2/graph/payments/`;

export const GRAPH_ENDPOINT_PAYMENTS_V2 =
  process.env.NODE_ENV === 'development'
    ? 'https://qa.creha.co/api/graph/payments/'
    : '/api/graph/payments/';

export const GRAPH_ENDPOINT_B2B = `${APOLLO_CLIENT_DOMAIN}/api/v2/graph/b2b/`;

export const GRAPH_ENDPOINT_V4_B2B =
  process.env.NODE_ENV === 'development'
    ? 'https://qa.creha.co/api/graph/b2b/'
    : '/api/graph/b2b/';

export const GRAPH_ENDPOINT_V4_STAFF = `${APOLLO_CLIENT_DOMAIN}/api/graph/staff/`;

export const GRAPH_ENDPOINT_PRIVATE = `${APOLLO_CLIENT_DOMAIN}/api/v2/graph/private/`;

export const GRAPH_ENDPOINT_SEARCH = `${APOLLO_CLIENT_DOMAIN}/api/v2/graph/search/`;

export const GRAPH_ENDPOINT_V3 = `${APOLLO_CLIENT_DOMAIN}/api/v3/graph/`;

export const GRAPH_ENDPOINT_V4 = `${APOLLO_CLIENT_DOMAIN}/api/graph/`;

export const GRAPH_ENDPOINT_V5 = `${APOLLO_CLIENT_DOMAIN}/api/v5/graph/`;

export const BASE_API_URL =
  API_FROM_GLOBAL || process.env.BASE_API_URL || GRAPH_ENDPOINT;

export const CREDENTIALS = process.env.BASE_API_URL ? 'include' : 'same-origin';
