import { ApolloLink } from '@apollo/client';

import { CountryCode } from '@crehana/ts-types';

// Case it is not provided fallback to reading from initial state
import getCountryPrefix from 'Jsx/Utils/getCountryPrefix';

const currencyLink = (countryCode?: CountryCode) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'Creh-Country': countryCode || getCountryPrefix(),
      },
    }));

    return forward(operation);
  });

export default currencyLink;
