/* eslint-disable */

self.fetch ||
  (self.fetch = function(e, n) {
    return (
      (n = n || {}),
      new Promise((t, r) => {
        let s = new XMLHttpRequest();

        for (let o in (s.open(n.method || 'get', e, !0), n.headers))
          s.setRequestHeader(o, n.headers[o]);
        function u() {
          let e,
            n = [],
            t = [],
            r = {};

          return (
            s
              .getAllResponseHeaders()
              .replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm, (s, o, u) => {
                n.push((o = o.toLowerCase())),
                  t.push([o, u]),
                  (r[o] = (e = r[o]) ? `${e  },${  u}` : u);
              }),
            {
              ok: ((s.status / 100) | 0) == 2,
              status: s.status,
              statusText: s.statusText,
              url: s.responseURL,
              clone: u,
              text() {
                return Promise.resolve(s.responseText);
              },
              json() {
                return Promise.resolve(s.responseText).then(JSON.parse);
              },
              blob() {
                return Promise.resolve(new Blob([s.response]));
              },
              headers: {
                keys() {
                  return n;
                },
                entries() {
                  return t;
                },
                get(e) {
                  return r[e.toLowerCase()];
                },
                has(e) {
                  return e.toLowerCase() in r;
                },
              },
            }
          );
        }
        (s.withCredentials = n.credentials == 'include'),
          (s.onload = function() {
            t(u());
          }),
          (s.onerror = r),
          s.send(n.body || null);
      })
    );
  });
