// @ts-nocheck
/**
 * This script replicate the same logic from chat.html django template.
 * We will use this to load the _chat_ in pages where the user is not comming..
 * ...from the initial state because the page is cached used clodflare.
 */

import { Me } from 'ContextApis/AuthContext';

import { loadScript } from '@crehana/utils';

/* Intercom script */
async function loadIntercomChat(
  user: NonNullable<Me>,
  isProd: boolean,
  isPlayroomActivated: boolean,
  hasElevate: boolean,
) {
  const APP_ID = 'mevliu65';
  const APP_ID_QA = 'if08s3kq';

  (function () {
    const w = window;
    const ic = w.Intercom;

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      /* eslint-disable */
      var i = function () {
        i.c(arguments);
      };
      /* eslint-enable */

      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');

        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${APP_ID}`;
        const x = d.getElementsByTagName('script')[0];

        x.parentNode.insertBefore(s, x);
      };

      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  window.Intercom('boot', {
    app_id: isProd ? APP_ID : APP_ID_QA,
    user_id: user.id,
    name: user.displayName,
    email: user.email,
    has_enrollments: !!user.hasEnrollment,
    has_active_degree: user.isActiveDegree,
    is_b2b: user.isB2B,
    is_premium: user.isPremium,
    has_playroom_activated: isPlayroomActivated,
    has_elevate: hasElevate,
    phone:
      user.phoneCode && user.phoneNumber
        ? `+${user.phoneCode} ${user.phoneNumber}`
        : undefined,
    avatar: {
      type: 'avatar',
      image_url: user.profilePictureUrl,
    },
    company: user.organization
      ? {
          company_id: user.organization.originalId,
          name: user.organization.name,
        }
      : undefined,
  });
}
async function loadSalesforceChatQA(user: NonNullable<Me>) {
  function initESW(gslbBaseURL: string | null) {
    embedded_svc.settings.displayHelpButton = true; // Or false
    embedded_svc.settings.language = 'es'; // For example, enter 'en' or 'en-US'

    embedded_svc.settings.defaultMinimizedText = '¿Necesitas ayuda?'; // (Defaults to Chat with an Expert)
    // embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    embedded_svc.settings.loadingText = 'Cargando'; // (Defaults to Loading)
    // embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    // embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    // };
    // embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    embedded_svc.settings.offlineSupportMinimizedText = '¿Necesitas ayuda?'; // (Defaults to Contact Us)

    // Avatar
    embedded_svc.snippetSettingsFile.chatbotAvatarImgURL =
      'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/09/10/074b346d/d0eb7afa.png';

    embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: user.firstName || '',
      LastName: user.lastName || '',
      Email: user.email || '',
      Subject: '',
    };

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.init(
      'https://crehana.my.salesforce.com',
      'https://crehana.force.com/ayuda',
      gslbBaseURL,
      '00D4x000006sIpY',
      'Chat',
      {
        baseLiveAgentContentURL:
          'https://c.la3-c1-ia4.salesforceliveagent.com/content',
        deploymentId: '5724x0000004uUx',
        buttonId: '5734x0000004vT2',
        baseLiveAgentURL: 'https://d.la3-c1-ia4.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'Chat',
        isOfflineSupportEnabled: true,
      },
    );
  }
  await loadScript({
    src: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    defer: true,
  });
  if (!window.embedded_svc) {
    await loadScript({
      src: 'https://crehana.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      defer: true,
    });

    initESW(null);
  } else {
    initESW('https://service.force.com');
  }
}

async function loadSalesforceChatProd(user: NonNullable<Me>) {
  function initESW(gslbBaseURL: string | null) {
    embedded_svc.settings.displayHelpButton = true; // Or false
    embedded_svc.settings.language = 'es'; // For example, enter 'en' or 'en-US'

    embedded_svc.settings.defaultMinimizedText = '¿Necesitas ayuda?'; // (Defaults to Chat with an Expert)
    // embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    embedded_svc.settings.loadingText = 'Cargando'; // (Defaults to Loading)
    // embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    // embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    // };
    // embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    embedded_svc.settings.offlineSupportMinimizedText = '¿Necesitas ayuda?'; // (Defaults to Contact Us)

    // Avatar
    embedded_svc.snippetSettingsFile.chatbotAvatarImgURL =
      'https://crehana-public-catalog.imgix.net/images/app/gallery/2021/09/10/074b346d/d0eb7afa.png';

    embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: user.firstName || '',
      LastName: user.lastName || '',
      Email: user.email || '',
      Subject: '',
    };

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.init(
      'https://crehana.my.salesforce.com',
      'https://crehana.force.com/ayuda',
      gslbBaseURL,
      '00D4x000006sIpY',
      'CrehanaChat',
      {
        baseLiveAgentContentURL:
          'https://c.la3-c1-ia4.salesforceliveagent.com/content',
        deploymentId: '5724x0000004uUx',
        buttonId: '5734x0000004vTW',
        baseLiveAgentURL: 'https://d.la3-c1-ia4.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'CrehanaChat',
        isOfflineSupportEnabled: true,
      },
    );
  }
  await loadScript({
    src: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    defer: true,
  });
  if (!window.embedded_svc) {
    await loadScript({
      src: 'https://crehana.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      defer: true,
    });

    initESW(null);
  } else {
    initESW('https://service.force.com');
  }
}

function isQA() {
  return window.location.origin.includes('qa.creha.co');
}

function isStaging() {
  return (
    window.location.origin.includes('stg-') &&
    window.location.origin.includes('.creha.co')
  );
}

async function loadChatScript(
  user?: Me,
  isBr: boolean,
  isPlayroomActivated: boolean,
  hasElevate: boolean,
) {
  // Load chats only for logged in users
  if (!user) {
    return;
  }
  if (typeof window === 'undefined') {
    return;
  }
  if (window.HIDE_CHAT) {
    return;
  }

  const hasIntercomEnabled =
    user.isActiveDegree ||
    user.isB2B ||
    (user.isPremium && !user.isPremiumTrial);

  if (!isBr && !hasIntercomEnabled) {
    return;
  }

  if (!window.APP_DEBUG && process.env.NODE_ENV === 'production') {
    await loadIntercomChat(user, true, isPlayroomActivated, hasElevate);
  } else if (isQA() || isStaging()) {
    await loadIntercomChat(user, false, isPlayroomActivated, hasElevate);
  }
}

export default loadChatScript;
