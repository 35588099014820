import React from 'react';

import { SavedCoursesProvider } from '@crehana/web/dist/ReactContexts/SavedCoursesContext';

/**
 * Requerments:
 * - I18Context
 * - AuthContext
 */
const BasicSavedCoursesProvider: React.FC<{ isDark?: boolean }> = ({
  children,
  isDark = false,
}) => {
  return (
    <SavedCoursesProvider isDark={isDark}>{children}</SavedCoursesProvider>
  );
};

export default BasicSavedCoursesProvider;
